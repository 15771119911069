<template lang="pug">
  .car-classes-tab
    AppOverlayLoader(:state="carClassesLoading")
    .car-class-details(v-if="isCarClassFormShown")
      Form(
        :car-class-id="selectedCarClassId"
        @back="hideCarClassForm"
      )
    .car-classes(v-else)
      Filters(
        :filters="filters"
        @add-new="showCarClassForm"
        @change-car-types="handleSelectCarTypes($event)"
        @update-search-value="handleSearch($event)"
      )
      Table(
        :items="carClasses"
        :sorting-data="sorting"
        @select-car-class="selectCarClass"
        @remove-car-class="removeCarClass"
        @sorting="changeSorting"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"

  // store modules
  import carClassSettingsModule from "@/config/store/company_system/car_class_settings/car_classes"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const SERIALIZER_VIEW = "table"

  const carClassSettingsMixin = withStoreModule(carClassSettingsModule, {
    resetState: true,
    name: "carClassSettingsMixin",
    readers: {
      carClasses: "items",
      carClassesLoading: "loading",
      filters: "filters",
      sorting: "sorting",
      pagination: "pagination"
    },
    actions: {
      fetchCarClassesAction: "FETCH_ITEMS",
      deleteCarClass: "DELETE_ITEM"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      Form: () => import("./Form"),
      Filters: () => import("../Filters"),
      Table: () => import("./Table"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [carClassSettingsMixin, withConfirmation],

    created() {
      this.debouncedFetchCarClasses = appDebounce(() => this.fetchCarClasses())
    },

    data() {
      return {
        isCarClassFormShown: false,
        selectedCarClassId: null
      }
    },

    mounted() {
      this.fetchCarClasses()
    },

    methods: {
      fetchCarClasses() {
        this.fetchCarClassesAction({ serializer_view: SERIALIZER_VIEW })
      },

      hideCarClassForm() {
        this.isCarClassFormShown = false
        this.selectedCarClassId = null
        this.fetchCarClasses()
      },

      showCarClassForm() {
        this.isCarClassFormShown = true
      },

      handleSelectCarTypes(carTypes) {
        this.changeFilters({ ...this.filters, carTypes: carTypes })
      },

      handleSearch(value) {
        this.changeFilters({ ...this.filters, searchValue: value })
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchCarClasses()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchCarClasses()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchCarClasses()
      },

      selectCarClass(carClass) {
        this.selectedCarClassId = carClass.id
        this.showCarClassForm()
      },

      removeCarClass(carClass) {
        this.$confirm({
          title: this.$t("company_system.car_class_settings.delete_car_class_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCarClass(carClass.id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped></style>
